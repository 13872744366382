import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import InputField from '../Register/InputField';
import LogoImage from '../../assets/img/logo.png';
import { Link } from 'react-router-dom';

interface LoginFormComponentProps {
  onSubmit: (data: { username: string; password: string }) => void;
}

const LoginFormComponent: React.FC<LoginFormComponentProps> = ({ onSubmit }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ username?: string; password?: string }>({});

  const validateForm = () => {
    const newErrors: { username?: string; password?: string } = {};

    // Username validation: 6-8 characters long
    if (!/^[a-zA-Z0-9]{6,8}$/.test(username)) {
      newErrors.username = 'Username must be 6-8 characters long and contain only letters and numbers.';
    }

    // Password validation: at least 12 characters, 2 uppercase, 1 lowercase, 1 special character
    if (!/^(?=.*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,}$/.test(password)) {
      newErrors.password = 'Password must be at least 12 characters long, contain at least 2 uppercase letters, 1 lowercase letter, and 1 special character.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; 
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (validateForm()) {
      onSubmit({ username, password });
      // Clear form fields
      setUsername('');
      setPassword('');
      setErrors({});
    }
  };

  return (
    <Box sx={{ 
        maxWidth: 400, 
        mx: 'auto',  
        p: 2, 
        border: '1px solid #ccc', 
        background: 'linear-gradient(45deg, rgba(39, 199, 27, 0.1) 20%, rgba(70, 43, 182, 0.1) 50%, rgba(39, 199, 27, 0.1) 80%)',
        }}>
          <Link to="/" style={{ textDecoration: 'none', color: '#462bb6' }}>
                    &larr;
                </Link>
      <Typography variant="h4" color="primary" component="h1" sx={{ textAlign: 'center', mb: 2 }}>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          error={!!errors.username}
          helperText={errors.username}
        />
        <InputField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors.password}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login
        </Button>
        <br />
        <Box sx={{
            textAlign: 'center',
            margin: 2
        }}>
            Don't have an account? <a href="/register">Register</a>
        </Box> 
      </form>
    </Box>
  );
};

export default LoginFormComponent;