import React from 'react';
import { Box, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import Footer from '../../components/Shared/Footer';
import Header from '../../components/Shared/Header';

const HowScreen: React.FC = () => {
  const videoUrl = 'https://www.youtube.com/watch?v=YOUR_VIDEO_ID'; 

  return (
    <>
      <Header />
      <Box
        sx={{
          padding: '2rem',
          maxWidth: '800px',
          margin: 'auto',
          background: 'linear-gradient(45deg, rgba(39, 199, 27, 0.8) 20%, rgba(70, 43, 182, 0.8) 50%, rgba(39, 199, 27, 0.8) 80%)',
          color: 'white',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          How It Works
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to DocuLearn! In this section, we will explain how our platform works and how you can make the most of its features.
        </Typography>
        <Typography variant="body1" paragraph>
          Watch the video below for a quick overview of how DocuLearn can streamline your documentation process.
        </Typography>
        
        {/* React Player for YouTube Video Embed */}
        <ReactPlayer url={videoUrl} controls={true} width="100%" height="390px" />

        <Typography variant="body1" paragraph sx={{ marginTop: '2rem' }}>
          With DocuLearn, you can easily create, manage, and share documentation without the hassle. Our intuitive interface guides you through the process, ensuring that you spend less time writing and more time coding.
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or need assistance, feel free to reach out to our support team!
        </Typography>
      </Box>
      <Footer />
    </>
  );
};

export default HowScreen;