// src/components/Login/Login.tsx
import React from 'react';
import LoginFormComponent from '../../components/Login/LoginFormComponent';
import Box from '@mui/material/Box';
import LoginImage from 'assets/img/login.png';
import LogoImage from '../../assets/img/logo.png';
import useAuth from '../../hooks/use-auth';

const Login: React.FC = () => {

  const { login } = useAuth();

  const handleLogin = (data: { username: string; password: string }) => {
    login(data);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100vh',
        backgroundImage: `url(${LoginImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      
      <Box
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)', 
          border: '20px solid #462bb6',
          boxShadow: 2,
          zIndex: 1, 
          mt: 16
        }}
      >
        <LoginFormComponent onSubmit={handleLogin} />
      </Box>
    </Box>
  );
};

export default Login;